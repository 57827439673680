<template>
  <Loading key="loading" v-if="this.$store.getters.isLoading"/>
  <div v-else key="login" class="min-h-screen bg-background flex flex-col justify-center py-12 px-6 sm:px-6 lg:px-8 dark:bg-backgroundDark">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-primary shadow rounded-lg dark:bg-primaryDark p-8 pt-5">
          <p class="pb-4 text-center sm:text-3xl text-2xl font-bold text-gray-900 dark:text-white">
            Sign in to your account
          </p>
        <!-- Sign in with social media -->
        <!-- NOTE: Sign in with social media hasn't been implemented yet -->
        <!-- <div class="text-gray-700 dark:text-white items-center mb-4">
          <p class="text-sm pb-1 text-gray-500 dark:text-white">Sign in with:</p>
          <div class="grid grid-cols-3 gap-6 h-8">
            <div class="cursor-pointer flex justify-center border rounded-lg h-full hover:bg-gray-100 dark-hover:bg-gray-800">
              <font-awesome-icon icon="fa-brands fa-apple" class="self-center"/>
            </div>
            <div class="cursor-pointer flex justify-center border rounded-lg h-full hover:bg-gray-100 dark-hover:bg-gray-800">
              <font-awesome-icon icon="fa-brands fa-google" class="self-center"/>
            </div>
            <div class="cursor-pointer flex justify-center border rounded-lg h-full hover:bg-gray-100 dark-hover:bg-gray-800">
              <font-awesome-icon icon="fa-brands fa-facebook-f" class="self-center"/>
            </div>
          </div>
          <div class="my-6 grid grid-cols-3 sm:gap-1 items-center text-gray-400">
            <span class="border-b border-gray-400 sm:mr-1 mr-2" />
            <p class="flex flex-row sm:text-sm text-xs justify-center">or continue with</p>
            <span class="border-b border-gray-400 sm:ml-1 ml-2" />
          </div>
        </div> -->
          <form @submit.prevent="login" novalidate>
            <div class="mt-1 ">
                  <div class="space-y-1 mb-3">
                    <label for="email" class="block text-sm font-medium text-gray-700 dark:text-white">
                      Email address
                    </label>
                    <div class="mt-1 ">
                      <input v-model="state.email" id="email" name="email" type="email" autocomplete="email"  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:text-white dark:bg-primaryDark" />
                    </div>
                  </div>
              <!-----Errors------>
                <div :class="{ error: v$.email.$errors.length }">
                  <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                    <div class="error-msg text-red-600 text-sm">{{ error.$message }}</div>
                  </div>
                </div>


                  <div class="space-y-1 my-3">
                    <label for="password" class="block text-sm font-medium text-gray-700 dark:text-white">
                      Password
                    </label>
                    <div class="mt-1">
                      <input v-model="state.password" id="password" name="password" type="password" autocomplete="current-password" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:text-white dark:bg-primaryDark" />
                    </div>
                  </div>
              <!-----Errors------>
              <div :class="{ error: v$.password.$errors.length }">
                <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                  <div class="error-msg text-red-600 text-sm">{{ error.$message }}</div>
                </div>
              </div>

                <div class="flex justify-end mt-2">
                  <!-- <div class="flex items-center">
                    <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                    <label for="remember_me" class="ml-2 block text-sm text-gray-900 dark:text-gray-200">
                      Remember me
                    </label>
                  </div> -->

                  <div class="text-sm justify-self-end items-end">
                    <a @click="forgotPassword" class="cursor-pointer font-medium text-orange-600 hover:text-orange-500">
                      Forgot your password?
                    </a>
                  </div>
                </div>
                <success :message="alertMessage"  v-if="showSuccess" />
                <danger :message="alertMessage" dangerouslySetInnerHTML="{{__html:alertMessage}}" v-if="showDanger" />  
                <div class="mt-3">
                  <button type="submit"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-backgroundDark dark:text-white">
                    Sign in
                  </button>
                </div>
            </div>
          </form>
        </div>
      </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router";
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import Loading from "@/components/layout/Loading";
import success from '@/components/widgets/alertMessage/success'
import danger from '@/components/widgets/alertMessage/danger'

export default {
  components: { Loading,danger,success },
  data() {
        return {
          alertMessage: "",
          showSuccess:false,
          showDanger:false
        }
    },
    beforeMount() {
      
      if(this.$route.params.success===true || this.$route.params.success==='true'){
        this.alertMessage=this.$route.params.message
        this.showSuccess=true;
      }else if(this.$route.params.success===false || this.$route.params.success==='false'){
        console.log(this.$route.params.message)
        this.alertMessage=this.$route.params.message
         this.showDanger=true;
      }
      
    },
  setup () {
    const store = useStore()
    const router = useRouter()

    const state = reactive({
      email: '',
      password: ''
    })
    const rules = {
      email: { required, email },
      password: { required }
    }
    const v$ = useVuelidate(rules, state, {$lazy: true})

    return {
      v$,
      store,
      state,
      forgotPassword() {
        router.push({name: 'ForgotPassword'})
      }
    }
  },
  methods: {
    async login() {
          this.showSuccess=false;
          this.showDanger=false;
      this.state.email = this.state.email.toLowerCase() // Make sure email is all lowercase
      this.v$.$touch()
      if (this.v$.$error) return
      // await this.store.dispatch('Login', this.state)
      // if(this.$store.getters.isLoggedIn){
      //   await this.$router.push({name:'Home'})
      // } else {
      //   this.state.email = ''
      //   this.state.password = ''
      //   this.v$.$reset()
      // }
      try {
        await this.$store.dispatch('Login', this.state)
            .then(response => {
                         
            if (this.$store.getters.isLoggedIn) {
                // this.alertMessage=response
                // this.showSuccess=true;
                this.$router.push({ path: '/'})
                // setTimeout( () => this.$router.push({ path: '/'}), 2000);
            }else{
              console.log('SignInForm.handleSubmit response: ', response);  // works
               this.alertMessage=response
               this.showDanger=true;
            }
        }).catch(error => {
            console.log('big problems: ', error);
            this.alertMessage='Invalid login credentials'
            this.showDanger=true;
        });
    } catch (error) {
        console.log("Error: SignInForm.handleSubmit", error);
        this.alertMessage='Incorrect Credentials'
        this.showDanger=true;
    }
    }
  }
}


</script>
